.navbar-collapse>ul>li>a{
  color: black;
  font-weight:600;
  font-size: 15px;

} 
.navbar-collapse>ul>li>a:hover{
  color:  #33b5f7

} 

.nvb1{
  background-color: #33b5f7;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  padding: 9px 29px;
  font-size: 16px;
  text-decoration: none;
}
.nvb1:hover{

}
.banner-section {
  position: relative;
  overflow: hidden;

}
section{
  display: block;
}

.bg12{
  background-image: url("./assets/images/vector-bg.jpg");
  background-position:center;
  background-size: cover;
}
@media (max-width: 1550px){
.banner-elements-part {
    right: -115px;
}
}
.banner-elements-part {
  position:relative;
  right: 0%;
  bottom: 1px;
  width:115%;
  height:180%;
  background-position: center;
  background-size: cover;
}
.banner-elements-part.active .element-one {
  top: 62%;
  left: 49%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part .element-one {
  transition-delay: 0.7s;
  transition-duration: 0.5s;
}
.banner-elements-part .element-one, .banner-elements-part .element-two, .banner-elements-part .element-three, .banner-elements-part .element-four, .banner-elements-part .element-five, .banner-elements-part .element-six, .banner-elements-part .element-seven, .banner-elements-part .element-eight, .banner-elements-part .element-nine {
  position: absolute;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  opacity: 0;
}
title {
  font-size: 60px;
}
.banner-elements-part.active .element-one {
  top: 68%;
  left: 54%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part.active .element-two {
  top: 73%;
  left: 59%;
  opacity: 1;
}
.banner-elements-part .element-two {
  top: 52%;
  left: 54%;
}
.banner-elements-part .element-two {
  transition-delay: 1s;
  transition-duration: 0.5s;
}
.banner-elements-part.active .element-three {
  top: 49%;
  left: 35%;
  opacity: 1;
}
.banner-elements-part .element-three {
  top: 58%;
  left: 32%;
}
.banner-elements-part .element-three {
  transition-delay: 1s;
  transition-duration: 0.5s;
}
.banner-elements-part.active .element-four {
  top: 69%;
  left: 41%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part .element-four {
  top: 63%;
  left: 25%;
  z-index: 2;
}
.banner-elements-part .element-four {
  transition-delay: 1.3s;
  transition-duration: 0.5s;
}
.banner-elements-part.active .element-five {
  top: 30%;
  left: 39%;
  z-index: 1;
  opacity: 1;
}
.banner-elements-part .element-five {
  top: 40%;
  left: 35%;
  z-index: 1;
}
.banner-elements-part .element-five {
  transition-delay: 0.3s;
  transition-duration: 0.5s;
}
.banner-elements-part.active .element-six {
  top: 59%;
  left: 74%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part .element-six {
  top: 55%;
  left: 80%;
  z-index: 2;
}
.banner-elements-part .element-six {
  transition-delay: 1.2s;
  transition-duration: 0.5s;
}
.banner-elements-part.active .element-seven {
    top: 73%;
    left: 47%;
    z-index: 2;
    opacity: 1;
}
.banner-elements-part .element-seven {
  top: 78%;
  left: 43%;
  z-index: 2;
}
.banner-elements-part .element-seven {
  transition-delay: 1.7s;
  transition-duration: 0.5s;
}
.banner-elements-part.active .element-eight {
  top: 38%;
  left: 16%;
  opacity: 1;
}
.banner-elements-part .element-eight {
  top: 35%;
  left: 4%;
}
.banner-elements-part .element-eight {
  transition-delay: 2s;
  transition-duration: 0.5s;
}
.banner-elements-part.active .element-nine {
  top: 25%;
  left: 36%;
  z-index: 2;
  opacity: 1;
}
.banner-elements-part .element-nine {
  top: 10%;
  left: 31%;
  z-index: 2;
}
.banner-elements-part .element-nine {
  transition-delay: 1.2s;
  transition-duration: 0.5s;
}
.h123{
  margin-top: 12%;
}
.btnl{
  width: 100%;
  background-color: #33b5f7;
  color: #ffffff;
  font-weight: bold;
}
.lt{
text-align: center;
margin-top: 25px;
}
.cht{
  text-align: center;
  margin-top: 15px;
  
}
.ticket{
  background-image: url("./assets/images/ticket.png");
  background-size:cover;
  background-position: 2%;
height: 180px;
  text-align: center;
  border-radius: 25px;
  margin: 5px;
  cursor: pointer;
  

}
.ticket>span{
position: relative;
font-size: 40px;
color: #0e0d0d;
font-weight: 700;
}
.cart-table {
  box-shadow: 0px 0px 20px 0px rgb(51 55 69 / 20%);
  border-radius: 10px;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.cart-table thead {
  background-color: #ffffff;
  border-bottom: 1px solid #e5e5e5;
}
table {
  width: 100%;
}
table {
  border-collapse: collapse;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.cart-table thead th {
  padding: 10px;
  text-align: center;
  font-family: var(--heading-font);
  font-weight: 400;
  text-transform: uppercase;
  background-color: #ffffff;
}

.cart-table tbody {
  margin-top: 15px;
  background-color: #ffffff;
  overflow: hidden;
}

table tbody tr:last-child {
  border-bottom: none;
}
table tr td:first-child {
  padding-left: 20px;
}
.cart-table tbody td {
  text-align: center;
  text-transform: capitalize;
}
table td {
  padding: 18px 0;
}
.cart-summary-area {
  box-shadow: 0px 0px 20px 0px rgb(51 55 69 / 20%);
  overflow: hidden;
  padding: 20px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.sub-total-amount, .discount-amount {
  margin-bottom: 15px;
  padding: 0 30px;
}
.sub-total-amount .title, .discount-amount .title {
  font-family: var(--heading-font);
  color: var(--heading-color);
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}
.sub-total-amount, .discount-amount {
  margin-bottom: 15px;
  padding: 0 30px;
}
.sub-total-amount .title, .discount-amount .title {
  font-family: var(--heading-font);
  color: var(--heading-color);
  font-weight: 500;
  text-transform: capitalize;
  display: block;
}
.total-amount {
  background-color:#33b5f7;
  padding: 15px 30px 0 30px;
  text-align: center;
}
.total-amount .title {
  text-transform: uppercase;

  display: block;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
}
.total-amount .amount {
  font-size: 35px;
  font-weight: 700;
  display: block;
  text-align: left;
  color: #ffffff;
}
.total-amount .payment-btn {
  background-color:#33b5f7;
  box-shadow: -3.536px 3.536px 20px 0px rgb(51 55 69 / 20%);
  padding: 8px 35px;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  transform: translateY(18px);
  -webkit-transform: translateY(18px);
  -moz-transform: translateY(18px);
  -ms-transform: translateY(18px);
  -o-transform: translateY(18px);
}
button {
  border: none;
  cursor: pointer;
}

.total-amount .payment-btn span {
  color: #ffffff;
  margin-left: 5px;
}

.cart-summary-area .summary-area-title {
  color:#33b5f7;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 40px;
  padding-left: 30px;
}

.pcp{
  margin-top: 8%;
}
.profile-menu{
  background-color: #33b5f7;
  color: #ffffff;
  margin-top: 12px;
}
.profile-menu>ul>li>a{
  margin-left: 15px;

}
.profile-pictures{
  height: 250px;
  background-color: #33b5f7;
  text-align: center;
  
}
.profile-pictures-top{
  background-color: #ffffff;
  height: 100px;
}
.ai{
  width: 100px;
  height: 100px;
  position: relative;
  bottom:50px;

}
.profile-pictures-header{
  position: relative;
  bottom: 35px;
  color: #ffffff;
}
.profile-pictures>span{
 position: relative;
 bottom: 30px;
 font-weight: bold;
  
}
.personal-detail{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
  
}
.personal-detail>div>span{
  margin-left: 5%;
  text-align: left;
 
}
.personal-detail>h6{
  border-bottom: 3px  #ffffff;
  border-bottom-style:dashed;
}
.account-setting{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
}
.account-setting>h6{
  border-bottom: 3px  #ffffff;
  border-bottom-style:dashed;
}
.email-address{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
}
.email-address>h6{
  border-bottom: 3px  #ffffff;
  border-bottom-style:dashed;
}
.phone-number{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
}
.phone-number>h6{
  border-bottom: 3px  #ffffff;
  border-bottom-style:dashed;
}
.security{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
}
.security>h6{
  border-bottom: 3px  #ffffff;
  border-bottom-style:dashed;
}
.ticket-header{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
}
.ticket-header>h6{
  border-bottom: 3px  #ffffff;
  border-bottom-style:dashed;
}
.ticket-past-draw{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
}
.ticket-past-draw>h6{
  border-bottom: 3px  #ffffff;
  border-bottom-style:dashed;
}
.ticket-table{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
  height: 350px;
}
.ticket-table>table>thead{
  background-color: #33b5f7;
  color: #ffffff;
}
.ticket-table>table>tbody>tr{
  background-color:#1c5c7c;
  color: #ffffff;
  font-size: 15px;
  border-bottom: 1px solid #ffffff;
}

.favorite-header{
  background-color: #33b5f7;
  border-radius: 10px;
  color: #ffffff;
}
.favorite-header>h6{
  border-bottom: 3px  #ffffff;
  border-bottom-style:dashed;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
.ts1{

  overflow-y: scroll;
  height: 250px;
}
.loth>p{
  color: rgb(145, 131, 131);
  font-size: 15px;
  text-align: center;
}
.loth>h1{

  text-align: center;
}


.drawb{
  text-align: center;



}
.drawb>.b6{
  background-color: #33b5f7;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  padding: 9px 29px;
  font-size: 16px;
  text-decoration: none;
  position: relative;
  width:22%;
  height:17%;
  margin-left:40%;
  top: 30px;

}
.drawc>img{
width: 200px;



}
.drawb>img{
  width: 200px;
  

  }
  .drawb>.r2{
    font-size: 50px;
  }
.drawc{
  text-align: center;
  border-bottom: 5px solid #33b5f7;
}
.drawc:hover{

}
.drawc>.s1{
 color: red;
 font-weight: bold;
 font-size: 25px;
}
.drawc>.s2{
  font-weight: 500;
}
.drawc>.s3{
  font-size: 14px;
  color: rgb(170, 166, 162);
}
.drawc>.b5{
  background-color: #33b5f7;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  padding: 9px 29px;
  font-size: 16px;
  text-decoration: none;
  position: relative;
  top: 16px;
}
.lotdh>.row>div>img{
  width: 140px;
}
.lotdh>.row>div>.s11{
font-size: 35px;
font-weight: 600;
}
.lotdh>.row>div>.sp12{
  font-size: 30px;
  font-weight: 600;
  color: #11a74a;
  }
  .textld{
    text-align: right;
    font-weight: 600;
    font-size: 35px;
  }

  .ticketlt{
    height: 400px;
    overflow-y: scroll;
  }

  .bgit{
    background-image:url('./assets/images/ticket.png');
   background-size: cover;
   width: 250px;
   height: 150px;
   text-align: center;
 
  }
  .bgit>span{
    vertical-align: middle;
   position: relative;
   top: 35%;
   font-size: 40px;
   color:#130404;
   font-weight: 600;
  }

  
  .bgit1{
    background-image:url('./assets/images/ticket.png');
   background-size: cover;
   width: 111px;
   height: 60px;
   text-align: center;
   position: relative;
   left: 37%;
  
  }
  .bgit1>span{
    vertical-align: middle;
   position: relative;
   top: 35%;
   font-size: 14px;
   color:#130404;
   font-weight: 600;
  }
  
  .b5>a{
text-decoration: none;
color: #e5e5e5;
  }
  .b5:disabled{
    background-color: #a9d4e9;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    padding: 9px 29px;
    font-size: 16px;
    text-decoration: none;
    position: relative;
    top: 16px;
  }
  .b5{
    background-color:#33b5f7;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    padding: 9px 29px;
    font-size: 16px;
    text-decoration: none;
    position: relative;
    top: 16px;
  }
  
  body {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  
  a {
    color: #666;
  }
  
  h1 {
    font-weight: bold;
    margin: 0 0 10px;
  }
  
  h4 {
    margin-bottom: 30px;
  }
  
  h1,
  h2,
  h4 {
    text-align: center;
  }
  
  .App-payment {
    padding: 30px;
  }
  
  form {
    margin: 30px auto 0;
    max-width: 400px;
  }
  
  .form-actions {
    margin-top: 15px;
  }
  
  .App-cards {
    margin: 0 auto;
    max-width: 1280px;
    text-align: center;
  }
  
  .App-cards-list {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .App-cards-list > * {
    transform: scale(0.8);
    margin-bottom: 30px !important;
  }
  
  .App-highlight {
    font-family: serif;
    margin: 15px auto 0;
    max-width: 300px;
  }
  
  .App-highlight > div {
    padding-left: 40px;
  }
  
  .App-badges {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .App-badges a {
    display: block;
  }
  
  .App-badges a + a {
    margin-top: 18px;
  }
  
  .github__btn {
    align-items: center;
    background-color: #ccc;
    border-radius: 4px;
    color: #444;
    display: flex;
    line-height: 1;
    padding: 8px 12px;
  }
  
  .github__btn:hover {
    color: #444;
    text-decoration: none;
  }
  
  .github__btn img {
    display: inline-block;
    height: 28px;
    margin-right: 8px;
  }
  
  .App-credits {
    background-color: #000;
    color: #fff;
    line-height: 50px;
    text-align: center;
  }
  
  .App-credits a {
    color: #fff;
    font-weight: bold;
  }
  
  @media screen and (min-width: 600px) {
    .App-badges {
      flex-direction: row;
    }
  
    .App-badges a + a {
      margin-top: 0;
      margin-left: 18px;
    }
  }
  

  .payment-btn {
    background-color:#33b5f7;
    box-shadow: -3.536px 3.536px 20px 0px rgb(51 55 69 / 20%);
    padding: 8px 35px;
    color: #ffffff;
    text-transform: capitalize;
    border-radius: 999px;
    width: 150px;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px;
    transform: translateY(18px);
    -webkit-transform: translateY(18px);
    -moz-transform: translateY(18px);
    -ms-transform: translateY(18px);
    -o-transform: translateY(18px);
    position: relative;
    left: 26%;
bottom: 10px  }